.dash-card-info.dash-icon-5 {
	background-image: url(../../assets/images/icon-bulb.svg);
}

.dash-card-info.dash-icon-6 {
	background-image: url(../../assets/images/marker-2.svg);
}

.modalcontent {
	position: fixed; /* Stay in place */
	height: 40%; /* Full height */
	top: -500px;
	width: 600px;
	margin: auto;
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
